$backgroundHtml: #383838;

$backgroundPrimary: #444444;
$backgroundSecondary: #4D4D4D;

$colorPrimary: #fff;
$colorSecondary: #48FFFE;


$backgroundScrollbarTrack: #ddd;
$backgroundScrollbarThumb: #383838;
$backgroundScrollbarThumbHover: #4D4D4D;
$backgroundScrollbarThumbActive: #444444;

@mixin Scroll ($name) {
    .#{$name}::-webkit-scrollbar {
        width: 10px;
    }
    
    .#{$name}::-webkit-scrollbar-track {
        border-radius: 10px;
        background: $backgroundScrollbarTrack;
    }
    
    .#{$name}::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: $backgroundScrollbarThumb;
    }
    
    .#{$name}::-webkit-scrollbar-thumb:hover {
        border-radius: 10px;
        background: $backgroundScrollbarThumbHover;
    }
    
    .#{$name}::-webkit-scrollbar-thumb:active {
        border-radius: 10px;
        background: $backgroundScrollbarThumbActive;
    }
}
