#createApplication {
    a {
        transition: all .4s;
        color: #303B44;
        text-decoration: underline;
    
        &:hover {
            cursor: pointer;
            text-decoration: none;
            // color: #fff;
        }
    
        &:active, &:focus {
            color: #303B44;
        }
    }

}


.CB01 { // Стиль блок секции 01 (Основной блок)
    margin-top: 120px;
    border-radius: 40px;
    position: relative;

    .block { 
        padding: 0 60px;
        gap: 10px;
        display: flex;

        img {
            height: 600px;
        }
    }

    .content {
        flex-grow: 1;
        color: #fff;
        margin: auto 0;

        gap: 30px;
        display: flex;
        flex-direction: column;

        article {
            gap: 15px;
            display: flex;
            flex-direction: column;
        }

        aside {
            gap: 20px;
            display: flex;
        }
    }
}

.CB02 { // Стиль блок секции 02 (О курсе)
    margin-top: 100px;
    position: relative;

    gap: 40px;
    display: flex;

    img {
        margin: auto;
        max-width: 462px;
    }

    article {
        flex-grow: 1;
        margin: auto 0;
        font-size: 18px;
        line-height: 160%;

        gap: 10px;
        display: flex;
        flex-direction: column;
    }
}

.CB03 { // Стиль блок секции 03 (Кому подойдет этот курс)
    margin-top: 100px;
    border-radius: 40px;
    position: relative;

    .content {
        padding: 60px;

        gap: 40px;
        display: flex;
        flex-direction: column;

        h2 { text-align: center; }

        .items {
            gap: 60px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            
            .item {
                max-width: 500px;
                
                gap: 20px;
                display: grid;
                grid-template-columns: auto 1fr;

                img {
                    width: 150px;
                    height: 150px;
                }

                article {
                    margin: auto 0;

                    gap: 10px;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}

.CB04 { // Стиль блок секции 04 (Чему вы научитесь)
    margin-top: 100px;
    position: relative;

    gap: 40px;
    display: flex;
    flex-direction: column;

    h2 { text-align: center; }

    .items {
        gap: 60px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .item {
            gap: 10px;
            display: flex;
            flex-direction: column;

            img {
                height: 16px;
                width: max-content;
            }
        }
    }
}

.CB05, .CB10 { // Стиль блок секции 05 и 10 (Программа курса) (Вопросы и ответы)
    margin-top: 100px;
    position: relative;

    gap: 40px;
    display: flex;
    flex-direction: column;

    h2 { text-align: center; }

    .items {
        padding-bottom: 8px;
        background: transparent;

        gap: 30px;
        display: flex;
        flex-direction: column;

        .item {
            margin-right: 8px;
            padding: 5px 15px;
            box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
        
            border-radius: 30px;
            border: 2px solid #fff;

            .ant-collapse-header {
                font-size: 22px;
                font-weight: 600;
                line-height: 140%;
                font-family: 'Montserrat', sans-serif;
    
                gap: 20px;
                display: flex;
                align-items: center;
            }

            .ant-collapse-content-box {
                font-size: 18px;
                font-weight: 400;
                line-height: 200%;
                font-family: 'Inter', sans-serif;
            }
        }
    }
}

.CB06 { // Стиль блок секции 06 (Форматы обучения)
    margin-top: 100px;
    position: relative;

    gap: 40px;
    display: flex;
    flex-direction: column;

    h2 { text-align: center; }
    h4 { letter-spacing: 2px; }

    .items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
            padding: 30px;
            margin-bottom: 20px;
            flex-basis: 380px;
            border: 2px solid;
            background: #fff;
            border-radius: 30px;

            gap: 20px;
            display: grid;
            grid-template-rows: auto 1fr auto auto;

            .description {
                line-height: 200%;
            }

            .price {
                padding: 20px;
                font-weight: 700;
                letter-spacing: 1px;
                border-radius: 20px;
                background: #F1F1F1;

                gap: 10px;
                display: flex;
                flex-direction: column;

                .header { 
                    font-size: 22px;
                    text-align: center;
                }

                .sumMain {
                    color: #FF9900;
                    font-size: 46px;
                    text-align: center;
                }

                .withoutDisctount, .discount {
                    font-size: 20px;
                    text-align: center;
                }

                .discount {
                    color: #FF9900;
                }
            }
        }

        .itemBorderColor_1 { border-color: #9F9F9F; }
        .itemBorderColor_2 { border-color: #BFA46E; }
        .itemBorderColor_3 { border-color: #BF8F7A; }
    }
}

.CB07 { // Стиль блок секции 07 (Как проходит обучение)
    margin-top: 100px;
    border-radius: 40px;
    position: relative;

    h2 { text-align: center; }

    .content {
        padding: 60px;

        gap: 40px;
        display: flex;
        flex-direction: column;

        .items {
            gap: 60px;
            display: grid;
            grid-template-columns: 1fr 1fr;

            .item {
                max-width: 500px;
                
                gap: 20px;
                display: grid;
                grid-template-columns: auto 1fr;

                img {
                    width: 150px;
                    height: 150px;
                }

                article {
                    margin: auto 0;

                    gap: 10px;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}

.CB08 { // Стиль блок секции 08 (Лицензия)
    margin-top: 100px;
    position: relative;

    gap: 40px;
    display: flex;
    flex-direction: column;

    h2, h4, div { text-align: center; }
    h4 { font-weight: 400; }
    p { line-height: 160%; }
    h2>p { line-height: 120%; }
}

.CB09 { // Стиль блок секции 09 (Отзывы)
    margin-top: 100px;
    border-radius: 40px;
    position: relative;

    h2 {
        text-align: center;
    }

    .content {
        padding: 60px;

        gap: 40px;
        display: flex;
        flex-direction: column;

        .items {
            gap: 40px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            
            .item {
                max-width: 520px;
                display: grid;
                grid-template-rows: auto 1fr;
    
                img {
                    width: 250px;
                    height: 250px;
                    border-radius: 50%;
                    margin-bottom: -100px;
        
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    z-index: 1;
                    border: 10px solid #303B44;
                }
        
                article {
                    padding: 110px 30px 30px 30px;
                    text-align: center;
                    line-height: 160%;
                    border-radius: 30px;
                    background: #fff;
        
                    gap: 10px;
                    display: flex;
                    flex-direction: column;
                }    
            }
        }
    }
}

.CB11 { // Стиль блок секции 11 (Вопрос)
    color: #fff;
    margin-top: 100px;
    border-radius: 40px;
    position: relative;

    .comment { 
        font-size: 22px;
        font-weight: 500;
        line-height: 140%;
        font-family: 'Montserrat', sans-serif;
    }

    .content {
        padding: 60px;

        gap: 30px;
        display: flex;
        flex-direction: column;

        header {
            gap: 10px;
            display: flex;
            flex-direction: column;
        }

        .form {
            gap: 30px;
            display: grid;
            grid-template-rows: 1fr auto;
            grid-template-columns: 1fr 1fr 220px;

            div {
                grid-row-start: 2;
                grid-row-end: 3;
                
                grid-column-start: 1;
                grid-column-end: 4;
            }

            a {
                transition: all .4s;
                color: #FF9900;
                text-decoration: none;
            
                &:hover {
                    cursor: pointer;
                    text-decoration: none;
                    color: #fff;
                }
            
                &:active, &:focus {
                    color: #FF9900;
                }
            }
        }

        .answerClient {
            text-align: center;
            line-height: 180%;
            font-size: 26px;
        }

        .persData {
            position: relative;
            gap: 20px;
            display: flex;

            input[type=checkbox] {
                width: 24px;
                height: 24px;
                cursor: pointer;
                font-size: 17px;
                visibility: hidden;
                // position: absolute;
                top: 0;
                left: 0;
                transform: scale(1.5);
            }
            
            input[type=checkbox]:after {
                content: "";
                // background-color: #fff;
                display: inline-block;
                color: #fff;
                width: 24px;
                height: 24px;
                visibility: visible;
                border: 2px solid #fff;
                padding: 0 3px;
                // margin: 2px 0;
                border-radius: 8px;
                box-shadow: 0 0 15px 0 rgba(0,0,0,0.08), 0 0 2px 0 rgba(0,0,0,0.16);
            }
            
            input[type=checkbox]:checked:after {
                content: "\2714";
                display: unset;
                font-weight: bold;
                color: #FF9900;
                border: 2px solid #FF9900;
            }
        }
    }
}

.contentAnswer {
    text-align: center;
    font-size: 26px;
    line-height: 180%;
}



// Условие экран меньше 1300px
@media screen and (max-width: 1299px) {
    .CB06 { // Стиль блок секции 06 (Форматы обучения)
        .items {
            .item {
                flex-basis: 350px;
            }
        }
    }
}

// Условие экран меньше 1200px
@media screen and (max-width: 1199px) {
    .CB01 { // Стиль блок секции 01 (Основной блок)
        .block { 
            img {
                height: 500px;
            }
        }

        .buttonPrimary, .buttonSecondary { padding: 16px 25px; }
    }

    .CB02 { // Стиль блок секции 02 (О курсе)
        img {
            max-width: 420px;
        }
    }

    .CB06 { // Стиль блок секции 06 (Форматы обучения)
        .items {
            justify-content: space-evenly;

            .item {
                flex-basis: 340px;
            }
        }
    }

}

// Условие экран меньше 1100px
@media screen and (max-width: 1099px) {
    .CB11 { // Стиль блок секции 11 (Вопрос)
        .content {
            .form {
                gap: 30px;
                display: grid;
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-areas: 
                    'b1 b1 b2 b2'
                    'b4 b4 b4 b3';
    
                #b1 {
                    grid-area: b1;
                }

                #b2 {
                    grid-area: b2;
                }

                #b3 {
                    grid-area: b3;
                }

                #b4 {
                    align-self: center;
                    grid-area: b4;
                }
            }
        }
    }
}

// Условие экран меньше 1000px
@media screen and (max-width: 999px) {
    .CB01 { // Стиль блок секции 01 (Основной блок)
        .block { 
            padding: 0 40px;

            img {
                height: 400px;
            }

            aside {
                display: flex;
                flex-direction: column;
            }
        }

        // .buttonPrimary, .buttonSecondary { width: 300px; }
    }

    .CB02 { // Стиль блок секции 02 (О курсе)
        flex-direction: column;
    }

    .CB03 { // Стиль блок секции 03 (Кому подойдет этот курс)
        .content {
            .items { grid-template-columns: 1fr; }
        }
    }
    
    .CB04 { // Стиль блок секции 04 (Чему вы научитесь)
        .items { grid-template-columns: 1fr; }
    }
    
    .CB05, .CB10 { // Стиль блок секции 05 и 10 (Программа курса) (Вопросы и ответы)
        .items {
            .item {
                .ant-collapse-header { font-size: 18px; }
                .ant-collapse-content-box { font-size: 16px; }
            }
        }
    }

    .CB07 { // Стиль блок секции 07 (Как проходит обучение)
        .content {
            .items { grid-template-columns: 1fr; }
        }
    }
    
    .CB09 { // Стиль блок секции 09 (Отзывы)
        .content {
            .items {
                grid-template-columns: 1fr;
                
                .item {
                    margin: 0 auto;
                }
            }
        }
    }
    
}

// Условие экран меньше 800px
@media screen and (max-width: 799px) {
    .CB01 { // Стиль блок секции 01 (Основной блок)
        .block { 
            padding: 0 20px;
            display: flex;
            flex-direction: column-reverse;
    
            img {
                margin: 0 auto;
                // width: max-content;
                height: 300px;
            }
        }
    
        .content {
            text-align: center;
            padding-bottom: 25px;
    
            .buttonPrimary, .buttonSecondary { width: 300px; margin: 0 auto; }
        }
    }
    
    .CB03 { // Стиль блок секции 03 (Кому подойдет этот курс)
        .content {
            .items {
                gap: 60px;
                
                .item {
                    text-align: center;
                    grid-template-columns: 1fr;
    
                    img { margin: 0 auto; }
                }
            }
        }
    }
        
    .CB07 { // Стиль блок секции 07 (Как проходит обучение)
        .content {
            .items {
                .item {
                    text-align: center;
                    grid-template-columns: 1fr;
    
                    img { margin: 0 auto; }
                }
            }
        }
    }
    
    .CB09 { // Стиль блок секции 09 (Отзывы)
        .content {
            padding: 30px;
            grid-template-columns: 1fr;
        }
    }
    
    .CB11 { // Стиль блок секции 11 (Вопрос)
        .content {
            .form {
                gap: 30px;
                display: flex;
                flex-direction: column;
            }
        }
    }

}

// Условие экран меньше 500px
@media screen and (max-width: 499px) {
    .CB01 { // Стиль блок секции 01 (Основной блок)
        margin-top: 100px;

        .content {
            .buttonPrimary, .buttonSecondary { width: auto; margin: 0 0; }
        }
    }

    .CB02 { // Стиль блок секции 02 (О курсе)
        img {
            max-width: 320px;
        }
    }

    .CB11 { // Стиль блок секции 11 (Вопрос)
        .content {
            padding: 30px;
            
            .answerClient {
                font-size: 20px;
            }
        }
    }

    .myModalCourse {
        border-radius: 30px;
        box-shadow: none;

        .modalHead {
            text-align: center;
            font-size: 18px;
        }

        .modalContent {
            padding: 0px;
        }    

        .modalFooter {
            gap: 10px;
        }
    }

    .buttonPrimary, .buttonSecondary_2 { padding: 15px 20px; }
}