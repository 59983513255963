.main {
    line-height: 160%;

    gap: 20px;
    display: flex;
    flex-direction: column;

    ul>li { list-style-type: none; }
    ol>li { 
        left: 40px;
        position: relative;
        list-style-type: circle; 
    }


    a, .lic {
        cursor: pointer;
        color: #303B44;
        transition: all .5s;
        text-decoration: underline;

        &:hover {
            color: #8b8b8b;
        }
    }

    .table {
        gap: 10px;
        display: flex;
        flex-direction: column;

        .tRow {
            align-items: center;

            gap: 10px;
            display: grid;
            grid-template-columns: .1fr 1fr .2fr;
        }

        #center { text-align: center; }
    }
}