@import './../vars.scss';

@mixin TableContentGrid {
    gap: 20px;
    display: grid;
    grid-template-columns: .8fr 1.2fr 190px 180px;
}

.main {
    height: 100%;
    overflow: hidden;

    gap: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 23px 23px 0 0;
    background: $backgroundPrimary;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.25);

    .header {
        padding: 20px;

        display: grid;
        grid-template-columns: 1fr auto;

        aside {
            gap: 20px;
            display: flex;
        }
    }

    h3 {
        font-weight: 600;
        letter-spacing: 1px;
        color: $colorPrimary;
    }

    .content {
        height: 100%;
        padding: 20px 10px 10px 20px;
        overflow: hidden;
        border-radius: 23px 23px 0 0;
        background: $backgroundSecondary;
        box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.25);

        gap: 20px;
        display: flex;
        flex-direction: column;

        .tHead {
            color: #BDBDBD;
            font-size: 15px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 1px;
            margin: 0 40px 0 34px;
            font-family: 'Montserrat', sans-serif;

            @include TableContentGrid();
        }

        .tBody {
            height: 100%;
            overflow-y: scroll;
            padding-bottom: 10px;

            gap: 10px;
            display: flex;
            flex-direction: column;

            .tRow {
                color: $colorPrimary;
                padding: 10px 10px 10px 30px;
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 1px;
                font-family: 'Inter', sans-serif;
                background: rgba(56, 56, 56, 0.2);
                box-shadow: 2px 2px 6px 0px #48fffe;
                border-radius: 1000px;
                margin: 4px 20px 4px 4px;

                @include TableContentGrid();
            }
        }

        #uid {
            text-align: center;
            // border: 1px solid red;
        }

        #btn {
            margin: auto 0;
            gap: 20px;
            display: flex;
            justify-content: flex-end;
            // border: 1px solid red;
        }

        #uid,
        #name {
            margin: auto 0;
            background: transparent;
            // border: 1px solid red;
        }

        @include Scroll(tBody)
    }
}

.txtItem {
    color: #fff;

    gap: 10px;
    display: flex;
    flex-direction: column;
}

.fileTypeItem {
    height: 200px;
    padding: 20px 10px 20px 10px;
    border: 2px solid #48FFFE;
    border-radius: 30px 30px 30px 30px;
    background: $backgroundSecondary;
}

.content {
    height: 100%;
    overflow-y: scroll;

    .fileRow {
        padding: 10px;

        gap: 15px;
        display: flex;
        align-items: center;

        input[type=checkbox] {
            width: 22px;
            height: 22px;
            cursor: pointer;
            font-size: 14px;
            visibility: hidden;
            top: 0;
            left: 0;
            transform: scale(1.2);
            padding: 1px 3px;
            display: inline-block;
        }
        
        input[type=checkbox]:after {
            content: " ";
            // background-color: #fff;
            display: inline-block;
            color: #fff;
            width: 22px;
            height: 22px;
            visibility: visible;
            border: 2px solid #fff;
            padding: 0px 3px;
            // margin: -2px 0;
            border-radius: 6px;
            box-shadow: 0px 0px 2px 1px #48FFFE inset;
        }
        
        input[type=checkbox]:checked:after {
            content: "\2714";
            font-weight: bold;
            color: #48FFFE;
            border: 2px solid #48FFFE;
            box-shadow: 0px 0px 2px 1px #48FFFE;
        }
    }
}

@include Scroll(content)