@import './../../vars.scss';

.main {
    height: 100%;
    // padding: 20px 30px;
    background: $backgroundHtml;
    border-radius: 30px 0 0 30px;
    box-shadow: 0px 0px 6px 2px #48FFFE;

    gap: 0px;
    display: flex;
    flex-direction: column;

    .header {
        padding: 20px 30px;
        align-items: center;

        gap: 10px;
        display: grid;
        grid-template-columns: 1fr auto;

        h4 {
            font-weight: 600;
            letter-spacing: 1px;
            color: $colorPrimary;
        }
    }

    .content {
        height: 100%;
        padding: 20px;
        overflow: hidden;
        color: $colorPrimary;
        background: $backgroundPrimary;
        border-radius: 30px 30px 0 30px;
        box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.25);

        gap: 20px;
        display: grid;
        grid-template-columns: 1fr auto;

        .contentData {
            height: 100%;
            padding-right: 20px;
            padding-left: 8px;
            letter-spacing: 1px;
            overflow-y: scroll;
            overflow-x: hidden;

            gap: 20px;
            display: flex;
            flex-direction: column;

            .courseActive {
                align-items: center;
                line-height: 28px;

                gap: 10px;
                display: grid;
                grid-template-columns: auto 1fr;


                input {
                    cursor: pointer;
                    -webkit-appearance: none;
                    appearance: none;
                    width: 140px;
                    padding-left: 36px;
                    margin: 0;
                    border-radius: 16px;
                    background: radial-gradient(circle 12px, #303B44 100%, transparent calc(100% + 1px)) #fff -52px;
                    transition: .5s ease-in-out;
                }

                input::before {
                    content: "ВЫКЛЮЧЕН";
                    letter-spacing: 1px;
                    font: 600 14px/34px 'Inter', sans-serif;
                    color: #303B44;
                    // text-shadow: 0 1px black;
                }

                :checked {
                    padding-left: 14px;
                    background-color: $colorSecondary;
                    background-position: 52px;
                }

                :checked::before {
                    content: "ВКЛЮЧЁН";
                }
            }

            .txtItem {
                display: flex;
                flex-direction: column;

                .itemHead {
                    align-items: center;
                    display: grid;
                    grid-template-columns: 1fr auto;
                }
            }

            .items {
                gap: 20px;
                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;
            }
        }

        @include Scroll(contentData);

        .item {
            color: #303B44;
            max-width: 370px;
            height: min-content;
            background: #fff;
            border-radius: 30px;
            margin-bottom: 20px;
            text-decoration: none;

            .img, .dropZone {
                cursor: pointer;
                width: 100%;
                height: 200px;
                padding: 20px;
                position: relative;
                color: $colorPrimary;
                border-radius: 30px 30px 0 0;
                transition: all .5s;

                h4 {
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    position: absolute;
                    font-weight: 600;
                    bottom: 20px;
                }
            }

            .dropZone {
                // border: 2px dashed red;
                opacity: 0.6;
            }

            .info {
                padding: 20px;
                line-height: 24px;

                gap: 20px;
                display: flex;
                flex-direction: column;

                header {
                    gap: 10px;
                    display: flex;
                    flex-direction: column;

                    .meta {
                        gap: 30px;
                        display: flex;
                    }

                    figure {
                        gap: 10px;
                        display: flex;
                    }
                }

                .description {
                    padding: 20px;
                    line-height: 170%;
                    border-radius: 20px;
                    background: #F1F1F1;
                }

                .start {
                    font-weight: 700;
                }
            }

            &:hover {
                box-shadow: 0px 0px 17px rgba(109, 109, 109, 0.5);

                .btnHover {
                    background: #797979;
                }
            }
        }
    }    
}