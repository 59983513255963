.main {
    line-height: 160%;
    
    gap: 20px;
    display: flex;
    flex-direction: column;

    a {
        color: #303B44;
        transition: all .5s;
        text-decoration: underline;

        &:hover {
            color: #8b8b8b;
        }
    }
}