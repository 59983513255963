@import './../../vars.scss';

.main {
    height: 100%;
    overflow: hidden;
    background: $backgroundHtml;
    border-radius: 30px 0 0 30px;
    box-shadow: 0px 0px 6px 2px #48FFFE;

    gap: 0px;
    display: flex;
    flex-direction: column;

    .header {
        padding: 20px 30px;
        align-items: center;

        gap: 10px;
        display: grid;
        grid-template-columns: 1fr auto;

        h4 {
            font-weight: 600;
            letter-spacing: 1px;
            color: $colorPrimary;
        }
    }

    .content {
        height: 100%;
        padding: 20px;
        overflow: hidden;
        color: $colorPrimary;
        background: $backgroundPrimary;
        border-radius: 30px 30px 0 30px;
        box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.25);

        gap: 30px;
        display: grid;
        grid-template-columns: 1fr auto auto auto 1fr;

        .btnBar {
            gap: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .itemsContainer {
            height: 100%;
            overflow: hidden;

            gap: 20px;
            display: flex;
            flex-direction: column;

            &_header {
                display: grid;
                grid-template-columns: 1fr auto;
            }

            &_list {
                height: 100%;
                padding: 4px;
                overflow-y: scroll;

                gap: 10px;
                display: flex;
                flex-direction: column;
            }

            .itemRow {
                color: $colorPrimary;
                padding: 10px 25px;
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 1px;
                font-family: 'Inter', sans-serif;
                background: rgba(56, 56, 56, 0.2);
                box-shadow: 2px 2px 6px 0px #48fffe;
                border-radius: 1000px;
                margin: 4px 20px 4px 4px;
                transition: .4s linear;
                cursor: pointer;

                &:hover {
                    box-shadow: 0px 0px 4px 2px #48fffe, 0px 0px 10px 2px #48fffe inset;
                }

                &_selected {
                    cursor: default;
                    box-shadow: 0px 0px 4px 2px #48fffe, 0px 0px 10px 2px #48fffe inset;
                }
            }

            @include Scroll(itemsContainer_list)
        }
    }    
}