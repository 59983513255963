$backgroundHtml: #E6E6E6;

$backgroundScrollbarTrack: #fff;
$backgroundScrollbarThumb: #303B44;
$backgroundScrollbarThumbHover: #414141;
$backgroundScrollbarThumbActive: #303B44;


$colorFontPrimary: #303B44;
$colorFontSecondary: #FF9900;
$colorFontHoverPrimary: #8b8b8b;