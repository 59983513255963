@import './vars.scss';

.dividerVerticalPrimary { // Разделительная линия, стиль 1
    border: 1px solid #EFF1F3;

    -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
}

.dividerVerticalSecondary { // Разделительная линия, стиль 2
    border: 0px solid #38383800;

    -webkit-box-shadow: 0px 0px 2px 1px #fff;
    -moz-box-shadow: 0px 0px 2px 1px #fff;
    box-shadow: 0px 0px 2px 1px #fff;
}

.dividerPrimary { // Разделительная линия, стиль 2
    -webkit-box-shadow: 0px 0px 2px 1px #48FFFE;
    -moz-box-shadow: 0px 0px 2px 1px #48FFFE;
    box-shadow: 0px 0px 2px 1px #48FFFE;
}


.btnSocialPrimary { // Кнопка соцсети, стиль 1
    display: flex;
    align-items: center;
    transition: all .4s;
    color: $colorFontSecondary;
    background: transparent;

    &:hover {
        cursor: pointer;
        text-decoration: none;
        color: $colorFontPrimary;
    }
}

.buttonPrimary {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    letter-spacing: 1px;
    padding: 16px 40px;
    background: #FF9900;
    border-radius: 100px;
    line-height: 24px;
    display: flex;
    align-items: center;
    transition: all .4s;
    border: 0;
    justify-content: center;

    &:hover {
        cursor: pointer;
        color: #fff;
        text-decoration: none;
        // background: #447FBB;
        box-shadow: 0px 0px 40px 2px #fff;
    }
}

.buttonSecondary {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    letter-spacing: 1px;
    padding: 16px 40px;
    background: transparent;
    border-radius: 100px;
    border: 2px solid #fff;
    line-height: 24px;
    display: flex;
    align-items: center;
    transition: all .4s;
    justify-content: center;

    &:hover {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        background: transparent;
        box-shadow: 0px 0px 40px 2px #fff, 0px 0px 20px 2px #fff inset;
    }
}

.buttonSecondary_2 {
    // min-width: 300px;
    color: $colorFontSecondary;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    letter-spacing: 1px;
    padding: 16px 40px;
    background: transparent;
    border-radius: 100px;
    border: 2px solid $colorFontSecondary;
    line-height: 24px;
    align-self: center;
    text-align: center;
    transition: all .4s;

    &:hover {
        color: $colorFontHoverPrimary;
        border: 2px solid $colorFontHoverPrimary;
        cursor: pointer;
        background: transparent;
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
        color: $colorFontSecondary;
    }
}

.buttonCourse {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    letter-spacing: 1px;
    padding: 16px 40px;
    background: #FF9900;
    border-radius: 100px;
    line-height: 24px;
    transition: all .4s;
    border: 0;
    text-align: center;
    

    &:hover {
        cursor: pointer;
        background: $colorFontHoverPrimary;
        // background: #447FBB;
        // box-shadow: 0px 0px 40px 2px #fff;
    }
}

.txtPrimary { // Стиль текстового поля на страницах Лэндинг и Курс основное
    padding: 15px 20px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 1px;
    font-family: 'Inter', sans-serif;
    border-radius: 20px;
    border: 2px solid #fff;
    background: transparent;
    transition: all .5s;

    &:focus {
        outline: none !important;
        // border:1px solid red;
        box-shadow: 0 0 6px 1px #fff, 0 0 10px 1px #fff inset;
    }

    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color:    #fff;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
       color:    #fff;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
       color:    #fff;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
       color:    #fff;
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
       color:    #fff;
    }
    
    &::placeholder { /* Most modern browsers support this now. */
       color:    #fff;
    }
}

.txtSecondary { // Стиль текстового поля на страницах Лэндинг и Курс при регистрации заявки
    padding: 15px 20px;
    color: #303B44;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 1px;
    font-family: 'Inter', sans-serif;
    border-radius: 1000px;
    border: 2px solid #8b8b8b;
    background: transparent;
    transition: all .5s;

    &:focus {
        outline: none !important;
        // border:1px solid red;
        box-shadow: 0 0 6px 1px #8b8b8b, 0 0 10px 1px #8b8b8b inset;
    }

    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color:    #8b8b8b;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
       color:    #8b8b8b;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
       color:    #8b8b8b;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
       color:    #8b8b8b;
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
       color:    #8b8b8b;
    }
    
    &::placeholder { /* Most modern browsers support this now. */
       color:    #8b8b8b;
    }
}


.myInputTextPrimary { // Стиль текстового поля в админке
    color: #fff;
    cursor: text;
    min-height: 60px;
    padding: 6px 6px 6px 20px;
    border-radius: 1000px;
    border: 2px solid #48FFFE;
    background: #5A5A5A;
    transition: all .5s;
    align-items: center;
    
    gap: 15px;
    display: flex;

    input {
        flex-grow: 1;
        width: 100%;
        height: auto;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 1px;
        font-weight: 500;
        transition: all .5s;
        font-family: 'Inter', sans-serif;
    
        border: none;
        background: transparent;

        &:focus {
            outline: none !important;
        }
        
        &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color:    #fff;
        }
        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
           color:    #fff;
        }
        &::-moz-placeholder { /* Mozilla Firefox 19+ */
           color:    #fff;
        }
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
           color:    #fff;
        }
        &::-ms-input-placeholder { /* Microsoft Edge */
           color:    #fff;
        }
        
        &::placeholder { /* Most modern browsers support this now. */
           color:    #fff;
        }
    }

    &:focus-within, &:hover {
        box-shadow: 0 0 8px 1px #48FFFE, 0 0 10px 1px #48FFFE inset;
    }
}

.myButtonPrimary { // Стиль кнопки в админке
    color: #303B44;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    letter-spacing: 1px;
    padding: 15px 25px;
    background: #48FFFE;
    border-radius: 100px;
    line-height: 27px;
    transition: all .4s;
    border: 0;
    text-align: center;
    border: 1px solid transparent;
    

    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 6px 2px #48FFFE, 0px 0px 10px 2px #48ffff5e inset;
    }
}

.myButtonSecondary { // Стиль кнопки в админке
    color: #48FFFE;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    letter-spacing: 1px;
    padding: 15px 25px;
    background: transparent;
    border-radius: 100px;
    line-height: 27px;
    transition: all .4s;
    border: 0;
    text-align: center;
    border: 2px solid #48FFFE;
    

    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 6px 2px #48FFFE, 0px 0px 10px 2px #48ffff inset;
    }
}

.myButtonIconPrimary { // Стиль кнопки иконки в админке
    display: flex;
    align-items: center;
    transition: all .4s;
    color: #fff;
    transition: all .5s;

    &:hover {
        cursor: pointer;
        text-decoration: none;
        color: #48FFFE;
    }
}

.myButtonIconSecondary { // Стиль кнопки иконки в админке
    display: flex;
    align-items: center;
    transition: all .4s;
    color: #48FFFE;
    transition: all .5s;

    &:hover {
        cursor: pointer;
        text-decoration: none;
        color: #fff;
    }
}

.myButtonIconTextPrimarySmall { // Стиль кнопки Иконка и текст в админке
    color: #48FFFE;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    letter-spacing: 1px;
    padding: 10px 20px;
    background: transparent;
    border: 2px solid #48FFFE;
    border-radius: 100px;
    line-height: 27px;
    transition: all .4s;
    text-align: center;
    align-items: center;
    
    gap: 10px;
    display: grid;
    grid-template-columns: auto 1fr;
    

    &:hover {
        cursor: pointer;
        color: #48FFFE;
        box-shadow: 0px 0px 6px 2px #48FFFE, 0px 0px 6px 6px #48ffff5e inset;
    }

    &:focus {
        color: #48FFFE;
    }
}

.myButtonIconPrimaryBorder { // Стиль кнопки Иконка в рамке в админке
    width: 44px;
    height: 44px;
    color: #48FFFE;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    letter-spacing: 1px;
    padding: 8px;
    background: transparent;
    border: 2px solid #48FFFE;
    border-radius: 100px;
    line-height: 27px;
    transition: all .4s;
    text-align: center;
    justify-content: center;
    
    display: flex;
    

    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 6px 2px #48FFFE, 0px 0px 6px 6px #48ffff5e inset;
    }

    &:disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: .1;
    }
}

.myButtonIconSecondaryBorder { // Стиль кнопки Иконка в рамке в админке
    width: 44px;
    height: 44px;
    color: #48FFFE;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    letter-spacing: 1px;
    padding: 8px;
    background: transparent;
    border: 2px solid #48FFFE;
    border-radius: 10px;
    line-height: 27px;
    transition: all .4s;
    text-align: center;
    justify-content: center;
    
    display: flex;
    

    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 6px 2px #48FFFE, 0px 0px 6px 6px #48ffff5e inset;
    }
}
