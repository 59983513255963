.main {
    padding-top: 120px;

    gap: 40px;
    display: flex;
    flex-direction: column;

    h3 { text-align: center; }

    .container {
        gap: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .item {
            max-width: 560px;
            margin-bottom: 40px;

            display: grid;
            grid-template-rows: auto 1fr;

            img {
                width: 250px;
                height: 250px;
                border-radius: 50%;
                margin-bottom: -100px;
    
                display: block;
                margin-left: auto;
                margin-right: auto;
                z-index: 1;
                box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
    
            article {
                padding: 110px 30px 30px 30px;
                text-align: center;
                line-height: 160%;
                border-radius: 30px;
                background: #fff;
    
                gap: 10px;
                display: flex;
                flex-direction: column;
            }    
        }
    }
}



// Условие экран меньше 800px
@media screen and (max-width: 849px) {
    .main {
        .container {
            grid-template-columns: 1fr;

            .item { margin: 0 auto }
        }
    }
}