.main {
    padding-top: 120px;

    gap: 40px;
    display: flex;
    flex-direction: column;

    h3 { text-align: center; }

    .container {
        gap: 40px;
        display: grid;
        grid-template-columns: auto 1fr;
    
        .nav {
            width: 300px;
            height: min-content;
            padding: 20px 10px;
            border-radius: 20px;
            background: #303B44;
    
            gap: 5px;
            display: flex;
            flex-direction: column;
    
            a {
                color: #fff;
                padding: 10px 10px;
                transition: all .6s;
                border-radius: 10px;
                border-top: 1px dotted #303B44;
                border-bottom: 1px dotted #303B44;

                &:hover {
                    color: rgb(255, 153, 0);
                    text-decoration: none;
                    border-top: dotted 1px rgba(255, 255, 255, .15);
                    border-bottom: dotted 1px rgba(255, 255, 255, .15);
                    background: rgba(0, 223, 252, .02);
                }
            }

            .linkActive {
                color: rgb(255, 153, 0);
                text-decoration: none;
                border-top: dotted 1px rgba(255, 255, 255, .15);
                border-bottom: dotted 1px rgba(255, 255, 255, .15);
                background: rgba(0, 223, 252, .02);
            }
        }
    }
}

// Условие экран меньше 850
@media screen and (max-width: 849px) {
    .main {
        .container {
            gap: 40px;
            display: grid;
            grid-template-columns: 1fr;
        
            .nav {
                display: none;
            }
        }
    }
}