@import './../../vars.scss';

.main {
    height: 100%;
    // padding: 20px 30px;
    background: $backgroundHtml;
    border-radius: 30px 0 0 30px;
    box-shadow: 0px 0px 6px 2px #48FFFE;

    gap: 0px;
    display: flex;
    flex-direction: column;

    .header {
        padding: 20px 30px;
        align-items: center;

        gap: 20px;
        display: grid;
        grid-template-columns: 1fr auto auto;

        h4, h5 {
            font-weight: 600;
            letter-spacing: 1px;
            color: $colorPrimary;
        }
    }

    .content {
        height: 100%;
        padding: 15px;
        overflow: hidden;
        color: $colorPrimary;
        background: $backgroundPrimary;
        border-radius: 30px 30px 0 30px;
        box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.25);

        .page {
            height: 100%;
            font-size: 16px;
            font-family: 'Inter', sans-serif;
            color: #303B44;
            overflow: hidden;
            background: #E6E6E6;
            border-radius: 22px 22px 0 22px;
            box-shadow: 0px 0px 6px 3px #48FFFE;

            .pageContent {
                height: 100%;
                overflow-y: scroll;
            }

            @include Scroll(pageContent)
        }

    }
}

.dropZone {
    transition: all .5s;
    opacity: 0.6;
    border-radius: 30px;
    box-shadow: 0px 0px 6px 3px #48FFFE, 0px 0px 6px 3px #48FFFE inset;
}