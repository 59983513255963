@import './../vars.scss';

.main {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: $backgroundHtml;

    display: flex;
    flex-direction: column;

    .header {
        padding: 5px;
        background: $backgroundPrimary;
        box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.25);

        display: grid;
        grid-template-columns: auto 1fr auto auto;

        img { margin: 5px; }

        aside {
            margin: auto 0;
            margin-right: 40px;
            gap: 30px;
            display: flex;
        }

        .userInfo {
            border-radius: 10px;
            background: $backgroundSecondary;

            gap: 10px;
            display: grid;
            grid-template-columns: auto 1fr;

            .userName {
                color: $colorPrimary;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 1px;
                font-family: 'Inter', sans-serif;
                margin: auto 0;
                margin-right: 20px;
            }
        }
    }

    .content {
        height: 100%;
        overflow: hidden;
        padding-top: 40px;

        display: flex;
        flex-direction: column;
    }
}


// ---------- #START Основное меню в админке
#containerMenu {
    margin: auto 0;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: 'Inter', sans-serif;
}

#containerMenu:after {
    content: "";
    display: block;
    clear: both;
    height: 0;
}

#menu {
    // border: 1px solid red;
    // @include blockShadow(#223c5033);
    display: flex;
    padding: 0;
    width: max-content;
    // max-width: max-content;
    margin: 0 auto;
    // border-radius: $borderRadiusSecondary;
    // background: $backgroundSecondary;
}

#menu, #menu ul {
    list-style: none;
}

#menu>li {
    position: relative;
    padding: 5px;
    perspective: none;
    align-self: center;
    text-align: center;
}

#menu a, #menu .aside {
    display: block;
    z-index: 10;
    padding: 8px 25px 8px 25px;
    text-decoration: none;
    color: $colorSecondary;
    line-height: 1.4;
    // font-weight: 500;
    // font-size: 15px;
    letter-spacing: 1px;
    background: transparent;
    // text-shadow: 0 1px 1px rgba(255, 255, 255, .9);
    transition: all .25s ease-in-out;
    cursor: pointer;
}

#menu>li:hover>a, #menu>li:hover>.aside {
    // background: #fff;
    color: $colorPrimary;
    text-shadow: none;
}

#menu li ul {
    position: absolute;
    left: 0;
    z-index: 1;
    width: max-content;
    padding: 10px;
    opacity: 0;
    visibility: hidden;
    border-radius: 10px;
    background: transparent;
    overflow: hidden;
    transform-origin: 50% 0%;
    text-align: left;
}

#menu>li>.dropDownMenu {
    gap: 10px;
    display: flex;
    align-items: center;
}

#menu li:hover ul {
    background: $backgroundPrimary;
    opacity: 1;
    visibility: visible;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    animation-name: swingdown;
    animation-duration: 1s;
    animation-timing-function: ease;
}

@keyframes swingdown {
    0% {
        opacity: .99999;
        transform: rotateX(90deg);
    }

    30% {
        // transform: rotateX(-20deg) rotateY(5deg);
        animation-timing-function: ease-in-out;
    }

    65% {
        // transform: rotateX(20deg) rotateY(-3deg);
        animation-timing-function: ease-in-out;
    }

    100% {
        // transform: rotateX(0);
        animation-timing-function: ease-in-out;
    }
}

#menu li li a {
    padding-left: 15px;
    color: $colorPrimary;
    text-shadow: none;
    border-top: dotted 1px transparent;
    border-bottom: dotted 1px transparent;
    transition: all .15s linear;
    border-radius: 10px;
}

#menu li li a:hover {
    color: $colorSecondary;
    border-top: dotted 1px rgba(255, 255, 255, .15);
    border-bottom: dotted 1px rgba(255, 255, 255, .15);
    background: rgba(0, 223, 252, .02);
}
// ---- #END Основное меню в админке