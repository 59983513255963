.LB01 { // Стиль блок секции 01 (Основной блок)
    margin-top: 120px;
    border-radius: 40px;

    .block { 
        padding: 0 60px;
        display: flex;

        img {
            height: 600px;
        }
    }

    .content {
        flex-grow: 1;
        color: #fff;
        margin: auto 0;

        gap: 30px;
        display: flex;
        flex-direction: column;

        article {
            gap: 15px;
            display: flex;
            flex-direction: column;
        }

        aside {
            gap: 20px;
            display: flex;
        }
    }
}

.LB02 { // Стиль блок секции 02 (О школе)
    margin-top: 100px;

    gap: 40px;
    display: flex;

    img {
        margin: auto;
        max-width: 462px;
    }

    article {
        margin: auto 0;
        font-size: 18px;
        line-height: 160%;
        user-select: none;

        gap: 10px;
        display: flex;
        flex-direction: column;
    }
}

.LB03 { // Стиль блок секции 03 (Чего вы хотите)
    margin-top: 100px;

    gap: 40px;
    display: flex;
    flex-direction: column;

    h2 { text-align: center; }

    .content {
        gap: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .item {
            margin-bottom: 30px;
            max-width: 560px;

            display: grid;
            grid-template-rows: auto 1fr;

            img {
                width: 250px;
                height: 250px;
                border-radius: 50%;
                margin-bottom: -100px;
    
                display: block;
                margin-left: auto;
                margin-right: auto;
                z-index: 1;
                border: 10px solid #E6E6E6;
            }
    
            article {
                padding: 110px 30px 30px 30px;
                text-align: center;
                line-height: 160%;
                border-radius: 30px;
                background: #fff;
    
                gap: 10px;
                display: flex;
                flex-direction: column;
            }    
        }
    }
}

.LB04 { // Стиль блок секции 04 (Курсы школы)
    margin-top: 100px;

    gap: 40px;
    display: flex;
    flex-direction: column;

    h2 { text-align: center; }

    .content {
        gap: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .item {
            color: #303B44;
            flex-basis: 370px;
            background: #fff;
            border-radius: 30px;
            margin-bottom: 20px;
            text-decoration: none;

            display: flex;
            flex-direction: column;

            .img {
                width: 100%;
                height: 200px;
                padding: 20px;
                position: relative;
                color: #fff;
                border-radius: 30px 30px 0 0;

                h4 { 
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    position: absolute;
                    font-weight: 600;
                    bottom: 20px;
                }
            }

            .info {
                flex-grow: 1;
                padding: 20px;
                line-height: 24px;

                gap: 20px;
                display: flex;
                flex-direction: column;

                header {
                    gap: 10px;
                    display: flex;
                    flex-direction: column;

                    .meta {
                        gap: 30px;
                        display: flex;
                    }

                    figure {
                        gap: 10px;
                        display: flex;
                    }
                }

                .description {
                    flex-grow: 1;
                    padding: 20px;
                    line-height: 170%;
                    border-radius: 20px;
                    background: #F1F1F1;
                }

                .start {
                    font-weight: 700;
                }
            }

            &:hover {
                box-shadow: 0px 0px 17px rgba(109, 109, 109, 0.5);
                
                .btnHover {
                    background: #797979;
                }
            }
        }
    }
}

.LB05 { // Стиль блок секции 05 (Лицензия)
    margin-top: 100px;

    gap: 40px;
    display: flex;
    flex-direction: column;

    h2, h4, p { text-align: center; }
    h4 { font-weight: 400; }
    p { line-height: 160%; }
}

.LB06 { // Стиль блок секции 06 (Об авторе)
    margin-top: 100px;
    background: #303B44;
    border-radius: 40px;

    .content {
        color: #fff;
        padding: 100px 40px 0 0;
        
        gap: 80px;
        display: flex;
        
        img {
            height: 600px;
            border-radius: 0 0 0 40px;
        }

        article {
            line-height: 200%;
            margin: auto 0;

            gap: 30px;
            display: flex;
            flex-direction: column;
        }
    }
}

.LB07 { // Стиль блок секции 07 (Отзывы)
    margin-top: 100px;

    gap: 40px;
    display: flex;
    flex-direction: column;

    h2 { text-align: center; }

    .content {
        gap: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .item {
            max-width: 560px;
            margin-bottom: 40px;

            display: grid;
            grid-template-rows: auto 1fr;

            img {
                width: 250px;
                height: 250px;
                border-radius: 50%;
                margin-bottom: -100px;
    
                display: block;
                margin-left: auto;
                margin-right: auto;
                z-index: 1;
                box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
    
            article {
                padding: 110px 30px 30px 30px;
                text-align: center;
                line-height: 160%;
                border-radius: 30px;
                background: #fff;
    
                gap: 10px;
                display: flex;
                flex-direction: column;
            }    
        }
    }
}

.LB08 { // Стиль блок секции 08 (Вопрос)
    color: #fff;
    margin-top: 100px;

    border-radius: 40px;
    background: #000099;

    .comment { 
        font-size: 22px;
        font-weight: 500;
        line-height: 140%;
        font-family: 'Montserrat', sans-serif;
    }

    .content {
        padding: 60px;

        gap: 30px;
        display: flex;
        flex-direction: column;

        header {
            gap: 10px;
            display: flex;
            flex-direction: column;
        }

        .form {
            gap: 30px;
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 1fr 220px;

            div {
                grid-row-start: 2;
                grid-row-end: 3;
                
                grid-column-start: 1;
                grid-column-end: 4;
            }

            a {
                transition: all .4s;
                color: #FF9900;
                text-decoration: none;
            
                &:hover {
                    cursor: pointer;
                    text-decoration: none;
                    color: #fff;
                }
            
                &:active, &:focus {
                    color: #FF9900;
                }
            }
            
        }


        .answerClient {
            text-align: center;
            line-height: 180%;
            font-size: 26px;
        }

        .persData {
            position: relative;
            gap: 20px;
            display: flex;

            input[type=checkbox] {
                width: 24px;
                height: 24px;
                cursor: pointer;
                font-size: 17px;
                visibility: hidden;
                // position: absolute;
                top: 0;
                left: 0;
                transform: scale(1.5);
            }
            
            input[type=checkbox]:after {
                content: "";
                // background-color: #fff;
                display: inline-block;
                color: #fff;
                width: 24px;
                height: 24px;
                visibility: visible;
                border: 2px solid #fff;
                padding: 0 3px;
                // margin: 2px 0;
                border-radius: 8px;
                box-shadow: 0 0 15px 0 rgba(0,0,0,0.08), 0 0 2px 0 rgba(0,0,0,0.16);
            }
            
            input[type=checkbox]:checked:after {
                content: "\2714";
                display: unset;
                font-weight: bold;
                color: #FF9900;
                border: 2px solid #FF9900;
            }
        }
    }
}



// Условие экран меньше 1300px
@media screen and (max-width: 1299px) {
    h1 { font-size: 46px; }
    h2 { font-size: 36px; }
    h3 { font-size: 26px; }
    h4 { font-size: 20px; }
    h6 { font-size: 18px; }

    .LB04 { // Стиль блок секции 04 (Курсы школы)
        .content {
            .item {
                flex-basis: 350px;
            }
        }
    }
}

// Условие экран меньше 1200px
@media screen and (max-width: 1199px) {
    h1 { font-size: 46px; }
    h2 { font-size: 36px; }
    h3 { font-size: 26px; }
    h4 { font-size: 20px; }
    h6 { font-size: 18px; }
    
    .LB01 { // Стиль блок секции 01 (Основной блок)
        .block { 
            img {
                height: 500px;
            }
        }

        .buttonPrimary, .buttonSecondary { padding: 16px 25px; }
    }

    .LB02 { // Стиль блок секции 02 (О школе)
        img {
            max-width: 420px;
        }
    }
    
    .LB04 { // Стиль блок секции 04 (Курсы школы)
        .content {
            justify-content: space-evenly;

            .item {
                flex-basis: 350px;
            }
        }
    }

    .LB06 { // Стиль блок секции 06 (Об авторе)
        .content {
            img { height: 500px; }
        }
    }
    
}

// Условие экран меньше 1100px
@media screen and (max-width: 1099px) {
    .LB08 { // Стиль блок секции 08 (Вопрос)
        .content {
            .form {
                gap: 30px;
                display: grid;
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-areas: 
                    'b1 b1 b2 b2'
                    'b4 b4 b4 b3';
    
                #b1 {
                    grid-area: b1;
                }

                #b2 {
                    grid-area: b2;
                }

                #b3 {
                    grid-area: b3;
                }

                #b4 {
                    align-self: center;
                    grid-area: b4;
                }
            }
        }
    }
}

// Условие экран меньше 1000px
@media screen and (max-width: 999px) {
    h1 { font-size: 32px; }
    h2 { font-size: 24px; }
    h3 { font-size: 22px; }
    h4 { font-size: 20px; }
    h6 { font-size: 18px; }

    .LB01 { // Стиль блок секции 01 (Основной блок)
        .block { 
            img {
                height: 400px;
            }

            aside {
                display: flex;
                flex-direction: column;
            }
        }

        // .buttonPrimary, .buttonSecondary { width: 300px; }
    }

    .LB02 { // Стиль блок секции 02 (О школе)
        flex-direction: column;
    }

    .LB06 { // Стиль блок секции 06 (Об авторе)
        .content {
            gap: 50px;
            padding: 40px 20px 0 0;

            img { height: 450px; }
        }
    }

}

// Условие экран меньше 800px
@media screen and (max-width: 799px) {
    .LB01 { // Стиль блок секции 01 (Основной блок)
        .block { 
            padding: 0 40px;
            display: flex;
            flex-direction: column-reverse;
    
            img {
                margin: 0 auto;
                // width: min-content;
                height: 300px;
            }
        }
    
        .content {
            text-align: center;
    
            aside {
                padding-bottom: 25px;
            }

            .buttonPrimary, .buttonSecondary { width: 300px; margin: 0 auto; }
        }
    }
    
    .LB03 { // Стиль блок секции 03 (Чего вы хотите)
        .content {
            grid-template-columns: 1fr;
        }
    }
    
    .LB06 { // Стиль блок секции 06 (Об авторе)
        .content {
            text-align: center;
            padding: 0 20px 30px 20px;
            display: flex;
            flex-direction: column;
            
            img {
                margin: 0 auto;
                height: 400px;
                border-radius: 0;
            }
    
            article {
                line-height: 200%;
                margin: auto 0;
    
                gap: 30px;
                display: flex;
                flex-direction: column;
            }
        }
    }
    
    .LB07 { // Стиль блок секции 07 (Отзывы)
        .content {
            grid-template-columns: 1fr;

            .item { margin: 0 auto }
        }
    }
    
    .LB08 { // Стиль блок секции 08 (Вопрос)
        .content {
            .form {
                gap: 30px;
                display: flex;
                flex-direction: column;
            }
        }
    }

}

// Условие экран меньше 500px
@media screen and (max-width: 499px) {
    h1 { font-size: 24px; }
    h2 { font-size: 22px; }
    h3 { font-size: 22px; }
    h4 { font-size: 20px; }
    h6 { font-size: 18px; }

    .LB01 { // Стиль блок секции 01 (Основной блок)
        margin-top: 100px;

        .block { 
            padding: 0 20px;
        }

        .content {
            .buttonPrimary, .buttonSecondary { width: auto; margin: 0 0; }
        }
    }

    .LB02 { // Стиль блок секции 02 (О школе)
        img {
            max-width: 320px;
        }
    }

    .LB08 { // Стиль блок секции 08 (Вопрос)
        .content {
            padding: 30px;

            .answerClient {
                font-size: 20px;
            }
        }
    }    
}