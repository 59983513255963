.main {
    line-height: 160%;
    user-select: none;
    text-align: justify;

    gap: 20px;
    display: flex;
    flex-direction: column;

    ul>li { list-style-type: none; }
    ol>li { 
        left: 40px;
        position: relative;
        list-style-type: circle; 
    }

    a {
        color: #303B44;
        transition: all .5s;
        text-decoration: underline;

        &:hover {
            color: #8b8b8b;
        }
    }
}