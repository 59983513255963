.main {
    margin-top: 120px;

    gap: 40px;
    display: flex;
    flex-direction: column;

    h3 { text-align: center; }

    .container {
        gap: 10px;
        display: flex;
        flex-direction: column;

        a {
            color: #303B44;
            transition: all .5s;
            text-decoration: underline;
    
            &:hover {
                color: #8b8b8b;
            }
        }    
    }
}