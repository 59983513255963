.main {
    width: 100%;
    height: 100%;
    background: #383838;

    .content {
        max-width: 350px;
        top: 50%;
        left: 50%;
        position: relative;
        margin-right: -50%;
        transform: translate(-50%, -50%);

        gap: 40px;
        display: flex;
        flex-direction: column;

        header {
            gap: 20px;
            display: flex;
            flex-direction: column;

            img {
                width: 80px;
                height: 80px;
                margin: 0 auto;
            }

            h4 {
                text-align: center;
                line-height: 27px;
                letter-spacing: 1px;
                background: linear-gradient(0deg, #48FFFE 0%, #fff 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        form {
            gap: 25px;
            display: flex;
            flex-direction: column;
        }
    }
}