@import './vars.scss';
@import 'antd/dist/reset.css';
@import '~rsuite/dist/rsuite.min.css';
@import "react-calendar/dist/Calendar.css";
@import 'suneditor/dist/css/suneditor.min.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
}

html,
body, #myApp {
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    color: $colorFontPrimary;
    background: $backgroundHtml;
    scroll-behavior: smooth;
    user-select: none;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        // border-radius: 50px;
        background: $backgroundScrollbarTrack;
    }

    &::-webkit-scrollbar-thumb {
        // border-radius: 50px;
        background: $backgroundScrollbarThumb;
    }

    &::-webkit-scrollbar-thumb:hover {
        // border-radius: 50px;
        background: $backgroundScrollbarThumbHover;
    }

    &::-webkit-scrollbar-thumb:active {
        // border-radius: 50px;
        background: $backgroundScrollbarThumbActive;
    }
}


h1 {
    font-size: 56px;
    font-weight: 700;
    line-height: 140%;
    font-family: 'Montserrat', sans-serif;
}

h2 {
    font-size: 46px;
    font-weight: 700;
    line-height: 140%;
    font-family: 'Montserrat', sans-serif;
}

h3 {
    font-size: 36px;
    font-weight: 700;
    line-height: 140%;
    font-family: 'Montserrat', sans-serif;
}

h4 {
    font-size: 26px;
    font-weight: 700;
    line-height: 140%;
    font-family: 'Montserrat', sans-serif;
}

h5 {
    font-size: 22px;
    font-weight: 700;
    line-height: 140%;
    font-family: 'Montserrat', sans-serif;
}

h6 {
    font-size: 18px;
    font-weight: 700;
    line-height: 140%;
    font-family: 'Montserrat', sans-serif;
}


.itemHover {
    border: 1px dashed #00000077;
    transition: all .5s;
    border-radius: 15px;

    &:hover {
        cursor: pointer;
        border: 1px solid #fff;
        box-shadow: 0px 0px 6px 3px #48FFFE, 0px 0px 6px 3px #48FFFE inset;
    }
}

.container { // Общий контейнер для всех блоков
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.mainHeader { // Общий заголовок для всех страниц
    width: 100%;
    top: 0;
    background: $backgroundHtml;
    position: fixed;
    padding: 10px 0;
    font-weight: 600;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
    z-index: 2;

    .container {
        display: grid;
        grid-template-columns: auto 1fr auto;

        .logo {
            gap: 10px;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: $colorFontPrimary;
        }

        .menu {
            margin: auto;

            gap: 30px;
            display: flex;

            a, span {
                transition: all .4s;
                color: $colorFontSecondary;
                text-decoration: none;
            
                &:hover {
                    cursor: pointer;
                    text-decoration: none;
                    color: $colorFontPrimary;
                }
            }
        }

        .bar {
            gap: 40px;
            display: flex;
            align-items: center;

            a {
                text-decoration: none;
                color: $colorFontPrimary;

                &:hover {
                    cursor: pointer;
                    color: $colorFontHoverPrimary;
                }
            }

            .social {
                gap: 20px;
                display: flex;

                a {
                    text-decoration: none;
                    color: $colorFontSecondary;
    
                    &:hover {
                        cursor: pointer;
                        color: $colorFontPrimary;
                    }
                }
            }

            #menuButton { display: none; }
        }
    }
}

.footer { // Общий подвал для всех страниц
    margin-top: 100px;
    padding-bottom: 40px;
    font-weight: 600;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;

    gap: 20px;
    display: flex;
    flex-direction: column;

    a {
        color: $colorFontPrimary;
        text-decoration: none;

        &:hover {
            cursor: pointer;
            color: $colorFontHoverPrimary;
        }
    }

    .header > a { color: $colorFontPrimary; }

    .header {
        gap: 40px;
        display: flex;
        flex-wrap: wrap;

        .logo {
            gap: 10px;
            display: flex;
            align-items: center;
        }

        .social {
            flex-grow: 1;
            justify-content: center;
            margin: auto;

            gap: 20px;
            display: flex;

            a {
                text-decoration: none;
                color: $colorFontSecondary;

                &:hover {
                    cursor: pointer;
                    color: $colorFontPrimary;
                }
            }
        }

        .contact {
            margin: auto 0;

            gap: 10px;
            display: flex;
            flex-direction: column;
        }
    }

    .nav {
        gap: 40px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .navItem {
            gap: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.menuMain {
    height: 100%;
    background: $backgroundHtml;
    border-radius: 40px 0 0 40px;
    // overflow: hidden;
    padding-bottom: 20px;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.40);

    .menuHeader {
        padding: 20px 30px;
        align-items: center;

        gap: 10px;
        display: grid;
        grid-template-columns: 1fr auto;

        h4 {
            font-weight: 600;
            letter-spacing: 1px;
            color: $colorFontPrimary;
        }
    }

    .menuBody {
        height: 100%;
        padding: 20px;
        overflow-y: auto;
        
        gap: 15px;
        display: flex;
        flex-direction: column;
        text-align: center;

        a {
            color: $colorFontPrimary;
            padding: 5px;
            border-radius: 5px;

            background: rgba(167, 167, 167, 0.1);

            &:hover {
                background: rgba(56, 56, 56, .2);
            }
        }
    }
}



// Условие экран меньше 1200px
@media screen and (max-width: 1199px) {
    .mainHeader { // Общий заголовок для всех страниц
        .container {
            .bar {
                .social { display: none; }
            }
        }
    }
}

// Условие экран меньше 1100px
@media screen and (max-width: 1099px) {
    .mainHeader { // Общий заголовок для всех страниц
        .container {
            grid-template-columns: 1fr auto;
    
            .bar {
                #phone { display: none; }
                .social { display: none; }
            }
        }
    }
}

// Условие экран меньше 850
@media screen and (max-width: 849px) {
    .mainHeader { // Общий заголовок для всех страниц
        .container {
            grid-template-columns: 1fr auto;
    
            .menu { display: none; }
    
            .bar {
                #menuButton { display: block; }
            }
        }
    }

    .footer { // Общий подвал для всех страниц
        .nav {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }    
}

// Условие экран меньше 700
@media screen and (max-width: 699px) {
    .footer { // Общий подвал для всех страниц
        .header {
            margin: 0 auto;
            gap: 40px;
            display: grid;
            grid-template-columns: 1fr;
            justify-content: center;
    
            .logo {
                gap: 10px;
                display: flex;
                align-items: center;
            }
    
            .contact { text-align: center; }
        }
    
        .nav {
            text-align: center;
            grid-template-columns: 1fr;

            .navItem { align-items: center; }
        }

        #copyright { text-align: center; }
    }    
}

// Условие экран меньше 400
@media screen and (max-width: 399px) {
    .mainHeader { // Общий заголовок для всех страниц
        .container {
            .logo {
                font-size: 14px;
                line-height: 140%;

                img { 
                    width: 50px; 
                    height: 50px; 
                }
            }
        }
    }    
}








// Мерцающий круг
// .container {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // width: 21px;
    // height: 21px;

    .circle {
        // width: 44px;
        // height: 44px;
        // background: red;
        position: relative;
        // border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        .red-medium-circle {
            color: #48FFFE;
            position: absolute;
            width: 48px;
            height: 48px;
            border-radius: 100%;
            background: transparent;
            // box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5), inset 2px 2px 4px rgba(0, 0, 0, .5);
            border: 1px solid #48FFFE;
            animation: pulsate infinite ease-in-out 1.6s;
            display: flex;
            justify-content: center;
            align-items: center;
        }
 
        @keyframes pulsate {
            0% {
                transform: scale(0.1);
                opacity: 0;
            }
        
            80% {
                opacity: 1;
            }
        
            100% {
                transform: scale(1.2);
                opacity: 0;
            }
        }
    }    
// }



.myModalPrimary {
    border-radius: 20px;
    background: #383838;
    background: -moz-linear-gradient(0deg, #383838 0%, #4D4D4D 100%);
    background: -webkit-linear-gradient(0deg, #383838 0%, #4D4D4D 100%);
    background: linear-gradient(0deg, #383838 0%, #4D4D4D 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#383838",endColorstr="#4D4D4D",GradientType=1);
    box-shadow: 0px 0px 6px 2px #48FFFE;

    .modalContent {
        gap: 30px;
        display: flex;
        flex-direction: column;
    }

    .modalHead {
        color: #fff;
        font-size: 22px;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 1px;
        font-family: 'Montserrat', sans-serif;

        gap: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .modalBody {
        gap: 30px;
        display: flex;
        flex-direction: column;
    }

    .modalFooter {
        gap: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;

    }
}

.myModalCourse {
    border-radius: 40px;
    background: #E6E6E6;
    box-shadow: 0px 0px 6px 1px #8b8b8b, 0px 0px 6px 1px #8b8b8b inset;

    .modalContent {
        padding: 20px;
        gap: 30px;
        display: flex;
        flex-direction: column;
    }

    .modalHead {
        color: #303B44;
        font-size: 22px;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 1px;
        font-family: 'Montserrat', sans-serif;
    }

    .modalBody {
        gap: 30px;
        display: flex;
        flex-direction: column;
    }

    .modalFooter {
        gap: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;

    }
}

.myDropZone {
    transition: all .5s;
    border-radius: 20px;
    // border: 1px dotted #48FFFE;
    box-shadow: 0px 0px 6px 2px #48FFFE, 0px 0px 40px 4px rgba(72, 255, 254, .5) inset;
}

.myPopoverVertical {
    top: -35px;
    right: -35px;
    width: max-content;
    position: absolute;
    color: #fff;
    background: #383838;
    border-radius: 20px;
    padding: 15px;
    box-shadow: 0px 0px 6px 3px #48FFFE;
    
    gap: 20px;
    display: flex;
    align-items: center;

    input {
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        width: 60px;
        padding-left: 37px;
        margin: 0;
        border-radius: 16px;
        background: radial-gradient(circle 12px, #303B44 100%, transparent calc(100% + 1px)) #fff -13px;
        transition: .5s ease-in-out;
    }

    input::before {
        content: "✘";
        letter-spacing: 1px;
        font: 600 14px/34px 'Inter', sans-serif;
        color: #303B44;
        // text-shadow: 0 1px black;
    }

    :checked {
        padding-left: 13px;
        background-color: #48FFFE;
        background-position: 13px;
    }

    :checked::before {
        content: "✔";
    }
}




// ---------- #START style SunEditor
.sun-editor {
    border-radius: 5px;

    .se-container {
        border-radius: 5px;
        border: 3px solid #48FFFE;

        .se-toolbar {
            border-radius: 5px;
            border-bottom: 3px solid #48FFFE;
            background-color: #fff;
        }

        .se-wrapper * {
            color: #fff;
            border-radius: 5px;
            background: #5A5A5A;
        }

        .se-resizing-bar {
            border-radius: 5px;
            background-color: #fff;
        }
    }
}
// ---- #END style Rsuite Drawer



// ---------- #START style AntD Notification
:where(.css-dev-only-do-not-override-26rdvq).ant-notification-notice .ant-notification-notice-message {
    color: #fff;
    margin: auto 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    font-family: 'Montserrat', sans-serif;
}
:where(.css-dev-only-do-not-override-26rdvq).ant-notification-notice {
    color: #fff;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 2px;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    border-radius: 20px;
    background: #383838;
    background: -moz-linear-gradient(0deg, #383838 0%, #4D4D4D 100%);
    background: -webkit-linear-gradient(0deg, #383838 0%, #4D4D4D 100%);
    background: linear-gradient(0deg, #383838 0%, #4D4D4D 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#383838",endColorstr="#4D4D4D",GradientType=1);
    box-shadow: 0px 0px 6px 2px #48FFFE;
}
:where(.css-dev-only-do-not-override-26rdvq).ant-notification-notice .ant-notification-notice-close {
    color: #fff;
}
// ---- #END style AntD Notification



// ---------- #START style Rsuite Drawer
.rs-drawer { box-shadow: none; }
.rs-drawer-content { background-color: transparent; }
// ---- #END style Rsuite Drawer



// ---------- #START style Rsuite Modal
.rs-modal-content {
    background: transparent;
    box-shadow: none;
}
.rs-modal-backdrop {
    z-index: 1050;
}
.rs-modal-wrapper{
    display: flex;
    align-items: center;
}
// ---- #END style Rsuite Modal



// ---------- #START style AntD Popover
.ant-popover { z-index: 1060; }
:where(.css-dev-only-do-not-override-26rdvq).ant-popover .ant-popover-inner {
    border-radius: 20px;
    background-color: #383838;
    box-shadow: 0px 0px 6px 3px #48FFFE;

}
:where(.css-dev-only-do-not-override-26rdvq).ant-popover .ant-popover-arrow:before {
    background: #383838;
}
// ---- #END style AntD Popover



// ---------- #START style React-Calendar
.react-calendar {
    width: 350px;
    max-width: 100%;
    background: transparent;
    border: 0px solid #48FFFE;
    border-radius: 20px;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.125em;
    color: #fff;
}
.react-calendar--doubleView {
    width: 700px; 
} 
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em; 
} 
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em; 
} 
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    box-sizing: border-box; 
} 
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none; 
}
.react-calendar button:enabled:hover {
    cursor: pointer; 
}
.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em; 
}
.react-calendar__navigation button {
    min-width: 44px;
    background: none; 
}
.react-calendar__navigation button:disabled {
    background-color: #48FFFE;
    color: #383838;
    font-weight: 600;
    border-radius: 10px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #48FFFE;
    color: #383838;
    border-radius: 10px;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em; 
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em; 
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold; 
}
.react-calendar__month-view__days__day--weekend {
    color: #48FFFE;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #a3a3a3;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em; 
}
.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px; 
}
.react-calendar__tile:disabled {
    background-color: red; 
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #48FFFE;
    border-radius: 10px;
    font-weight: 600;
    color: #383838;
}
.react-calendar__tile--now {
    background: #48FFFE;
    border-radius: 10px;
    font-weight: 600;
    color: #383838;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #fff;
    transition: all .5s;
}
.react-calendar__tile--hasActive {
    background: #48FFFE; 
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #fff; 
}
.react-calendar__tile--active {
    background: #48FFFE;
    color: #383838; 
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #fff; 
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: green; 
}
// ---- #END style React-Calendar
